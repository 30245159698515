import request from "../axios";

export default {
  name: "asr",
  //模型下拉：/main/common/select
  modelList() {
    return request({
      url: "/model/main/common/select",
      method: "get",
    })
  },
  //下拉目录下的所有文件：/main/audio/select
  fileList() {
    return request({
      url: "/model/main/audio/select",
      method: "get",
    })
  },
  //音频上传：/main/audio/uploadAudioList
  upload(data) {
    return request({
      url: "/model/main/audio/uploadAudioList",
      method: "post",
      data,
    })
  },
  //预览：/main/audio/test
  preview(params) {
    return request({
      url: "/model/main/audio/test",
      method: "get",
      params,
    })
  },
  //删除 /main/audio/delete
  delete(params) {
    return request({
      url: "/model/main/audio/delete",
      method: "get",
      params,
    })
  },
}
