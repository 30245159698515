import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux";
import "./index.scss"
import {Button, Collapse, Form, Input, Modal, Select, Image} from "antd";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined, StopOutlined } from "@ant-design/icons";
import Recorder from "../../util/funasr/recorder-core"
const Index = (props) => {
    /**state  state部分**/
    const [ fileForm] = Form.useForm();
    const [ modelList, setModelList ] = useState([
        { label:"普通话",value:"wss://asr.eorangeenergy.com/wss/original" },
        { label:"四川话",value:"wss://asr.eorangeenergy.com/wss/sichuan" },
        { label:"陕西话",value:"wss://asr.eorangeenergy.com/wss/shanxi" },
    ]);
    const [loading, setLoading] = useState(false);
    // 1 文件上传  2 语音识别
    const [ kind, setKind ] = useState(2);
    const [ record, setRecord ] = useState("");
    const offline_text = useRef(null);
    const recorder = useRef(null);
    const webSocket = useRef(null);
    const [ isStart, setIsStart ] = useState(false);//是否开始录音

    /**effect  effect部分**/


    /**methods 方法部分**/
    const normFile = (e) => {
        return  e?.fileList;
    };
    //表单提交
    const onFinish = (form) => {
        start(form.mode).then(r => {
            //文件上传
            if(kind === 1){
                setLoading(true)
                let reader = new FileReader();
                reader.onload = function(e) {
                    let binaryStr = e.target.result;
                    // 发送文件数据
                    webSocket.current.send(binaryStr);
                    webSocket.current.send(JSON.stringify({'is_speaking':false}))
                    setLoading(false);
                };

                reader.readAsArrayBuffer(form.fileList[0].originFileObj);
            }else{
                setIsStart(true);
                recorder.current = Recorder({
                    type:"pcm",
                    bitRate:16,
                    sampleRate:16000,
                    onProcess:recProcess
                });
                recorder.current.prototype.open(()=>{
                    recorder.current.prototype.start();
                },err=>{
                    console.error(err)});
            }
        });
    };
    //停止录音
    const onStopRecord = () => {
        if(recorder.current){
            recorder.current.prototype.close();
        }
        if(webSocket.current)webSocket.current.close();
        setIsStart(false);
    }
    let sendBuf,sampleBuf=new Int16Array();
    const start = (url)=> {
        return  new Promise((resolve, reject) => {
            if(webSocket.current) webSocket.current.close();
            webSocket.current = new WebSocket(url)
            webSocket.current.onopen = e =>{
                setRecord("")
                offline_text.current = ""
                webSocket.current.send(JSON.stringify({
                    "chunk_size": [5,10,5],
                    "wav_name":  "h5",
                    "is_speaking":  true,
                    "chunk_interval":10,
                    "itn":false,
                    "mode":"online",
                }))
                resolve();
            }
            webSocket.current.onerror = (event) => {
                window.globalMessage.error("当前模型不可用！")
            };
            webSocket.current.onmessage = (event) => {
                getJsonMessage(event);
            };
        });

    }
    const recProcess = ( buffer, powerLevel, bufferDuration, bufferSampleRate,newBufferIdx,asyncEnd ) =>{
        if ( webSocket.current?.readyState === webSocket.current?.OPEN ) {
            let data_48k = buffer[buffer.length-1];

            let  array_48k = new Array(data_48k);
            let data_16k=recorder.current.SampleData(array_48k,bufferSampleRate,16000).data;

            sampleBuf = Int16Array.from([...sampleBuf, ...data_16k]);
            let chunk_size=960;
            while(sampleBuf.length>=chunk_size){
                sendBuf=sampleBuf.slice(0,chunk_size);
                sampleBuf=sampleBuf.slice(chunk_size,sampleBuf.length);
                webSocket.current.send(sendBuf);

            }
        }
    }
// 语音识别结果; 对jsonMsg数据解析,将识别结果附加到编辑框中
    function getJsonMessage( data ) {
       let { text,mode:asrModel,timestamp } = JSON.parse(data.data)
        if(asrModel === "2pass-offline" || asrModel === "offline")
        {
            offline_text.current += text;
            setRecord(offline_text.current)
        } else{
            setRecord(pre => pre + text)
        }
    }
    const changeKind = (value) => {
        setKind(value);
        if(value === 1) {
            onStopRecord();
        }
    }
    /**styles 样式部分**/

    /**render**/

    return (
        <div className={"P-Asr"}>
            <div className={"header"}>
                <span style={{color: '#ed7843'}}>小橙</span>AI
            </div>
            <div className={"content"}>

                <div className={"P-Asr-left"}>
                    <Form initialValues={{ mode:"wss://asr.eorangeenergy.com/wss/original" }} form={fileForm} name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} onFinish={onFinish} >
                        <Form.Item label="模型" name="mode"  rules={[{ required: true, message: '请选择模型' }]} >
                            <Select options={modelList} />
                        </Form.Item>
                        <Form.Item label={"类型"}>
                            <Select value={kind} onChange={changeKind} options={[{ label: "文件", value: 1 }, { label: "麦克风", value: 2 }]} />
                        </Form.Item>
                        {
                            kind === 1 &&
                            <>
                                <Form.Item label="文件" valuePropName={"fileList"} name="fileList"  rules={[ { required: true, message: '请上传文件' }]} getValueFromEvent={normFile}>
                                    <Dragger name={"file"} beforeUpload={() => false} multiple accept={".wav"}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">将文件拖到此处，或<em>点击上传</em></p>
                                        <p className="ant-upload-hint"> 只能上传wav文件 </p>
                                    </Dragger>
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset:10 }} >
                                    <Button type="primary" htmlType="submit" loading={loading}>提交</Button>
                                </Form.Item>
                            </>
                        }
                        {
                            kind === 2 &&
                            <Form.Item wrapperCol={{ offset:8 }} >
                                <Button disabled={isStart} type="primary" htmlType="submit">开始</Button>
                                &nbsp;&nbsp;
                                <Button disabled={!isStart} type="primary" icon={<StopOutlined />} onClick={onStopRecord}>停止</Button>
                            </Form.Item>
                        }

                    </Form>
                </div>
                <div className={"P-Asr-right"}>
                    <div className={"P-Asr-right-record"}>
                        { record }
                    </div>
                </div>

            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {}
}
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(Index)
