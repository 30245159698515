import {configureStore, createAsyncThunk} from "@reduxjs/toolkit";
import user from "./user";


const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    user,
  }
})
export default store
