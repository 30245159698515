import React, {useEffect, useState} from 'react'
import router from "./router";
import {RouterProvider} from "react-router-dom";
import {ConfigProvider, message} from "antd";
import antConfig from "./util/antConfig";
import {Provider} from "react-redux";
import store from "./store";
import zhCN from 'antd/locale/zh_CN';
export const App = (props) => {

    message.config({
        top:20,
        rtl: true,
    })
    const [messageApi, contextHolder] = message.useMessage();
    window.globalMessage = messageApi

    /**state  state部分**/

    /**effect  effect部分**/

    /**methods 方法部分**/

    /**styles 样式部分**/

    /**render**/



    return (
        <>
            {contextHolder}
            <ConfigProvider locale={zhCN} theme={antConfig}>
                <Provider store={store}>
                    <RouterProvider router={router} />
                </Provider>
            </ConfigProvider>
        </>
    );
};
