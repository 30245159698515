import {createBrowserRouter, createHashRouter} from "react-router-dom";
import Home from "../pages/Home"
import Asr from "../pages/Asr";

const router =  createHashRouter([
    {
        path:"/",
        element:<Asr/>,
    },
])

export default router;
