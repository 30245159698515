import { createSlice } from "@reduxjs/toolkit";
import {getState, setState} from "../localStorage";

const userSlice = createSlice({
    name:"user",
    initialState:{
        token: getState("token") || "",
        userInfo: getState("userInfo") || {},
    },
    reducers:{
        setToken(state, { payload }){
            state.token = payload
            setState( { key:"token",value:payload } )
        },
        setUserInfo(state,{ payload }){
            state.userInfo = payload
            setState( { key:"userInfo",value:payload } )
        },
    }
})
export const { setToken ,setUserInfo} = userSlice.actions

export default userSlice.reducer