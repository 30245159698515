import {Pagination} from "antd";

const color="#ed7843"
const hoverColor = "#e59e78"
export default{

    components:{
        Button:{
            fontSize:16,
            colorPrimary:color,
            colorPrimaryHover:hoverColor,
            colorPrimaryActive:color,

        },
        Message:{
            top:20
        },
        Switch:{
            colorPrimary:color,
            colorPrimaryHover:hoverColor,
        },
        Tabs:{
            inkBarColor:color,
        },
        Radio:{
            colorPrimary:color,
            colorPrimaryHover:hoverColor,
        },
        Checkbox:{
            colorPrimary:color,
            colorPrimaryHover:hoverColor,
        },
        Table:{
            headerBg:color,
            headerColor:"#ffffff",
            colorLink:color
        },
        Pagination:{
            colorPrimary:color,
            colorPrimaryHover:hoverColor,
        }
    }
};