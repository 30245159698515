import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import "./util/flexlble";//自适应 rem
import "./util/global.scss";//引入全局样式
import {App} from "./App";
import http from "./api/http";
import store from "./store";


//挂载全局
window.$http = http
window.$store = store


ReactDOM.createRoot(document.getElementById('root')).render(
    <App/>
);

reportWebVitals();
