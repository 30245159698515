
export default {
    name:"util",
    /**
     * 流式请求数据
     * @param requestUrl
     * @param signal
     * @param _useState
     * @param parmas
     * @param header
     * @param isFirst 是否为第一次轮询 需要拿messageID
     * @returns {Promise<*>}
     */
    async requestStream(requestUrl,signal={},setMessage,params,header,isFirst = false){
        let message = "";
        try {
            let response = await fetch(requestUrl,{
                method:"POST",
                body:params,
                signal,
                headers:{
                    "Authorization":window.$store.getState("user").user.token,
                    ...header
                },
            });
            if (!response.ok) {
                window.globalMessage.error("fetchStream Network response was not ok")
                throw new Error('fetchStream Network response was not ok');
            }
            const reader = response.body.getReader();
            const textDecoder = new TextDecoder();
            let result = true;//是否信息结束完毕
            let responeParams = {}
            while (result) {
                const { done, value } = await reader.read();
                if (done) {
                    result = false;
                    break;
                }
                if(isFirst){
                    try {
                        //如果后端没给message_id捕获异常 自然显示出来
                        responeParams = eval(textDecoder.decode(value))
                    }catch (e){
                        setMessage(message+=textDecoder.decode(value))
                    }
                    isFirst = false
                }else{
                    setMessage(message+=textDecoder.decode(value))
                }
            }
            return {
                message,
                responeParams
            }
        } catch (e) {
            window.console.error("fetchStream产生异常:"+e)
            return Promise.reject(e)
        }
    }
}
