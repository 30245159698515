(function flexible(window, document) {
  var docEl = document.documentElement;
  var dpr = window.devicePixelRatio || 1;

  function setRemUnit() {
    var rem = docEl.clientWidth / 1920
    docEl.style.fontSize = rem *10 + "px";
  }

  setRemUnit();

  // reset rem unit on page resize
  window.addEventListener("resize", setRemUnit);
  window.addEventListener("pageshow", function (e) {
      if (e.persisted) {
          setRemUnit();
      }
  });

  // detect 0.5px supports
  if (dpr >= 2) {
      var fakeBody = document.createElement("body");
      var testElement = document.createElement("div");
      testElement.style.border = ".5px solid transparent";
      fakeBody.appendChild(testElement);
      docEl.appendChild(fakeBody);
      if (testElement.offsetHeight === 1) {
          docEl.classList.add("hairlines");
      }
      docEl.removeChild(fakeBody);
  }
  //获取屏幕缩放比例
    function getRatio(){
        var ratio=0;
        var screen=window.screen;
        var ua=navigator.userAgent.toLowerCase();

        if(window.devicePixelRatio !== undefined)
        {
            ratio=window.devicePixelRatio;
        }
        else if(~ua.indexOf('msie'))
        {
            if(screen.deviceXDPI && screen.logicalXDPI)
            {
                ratio=screen.deviceXDPI/screen.logicalXDPI;
            }

        }
        else if(window.outerWidth !== undefined && window.innerWidth !== undefined)
        {
            ratio=window.outerWidth/window.innerWidth;
        }

        if(ratio)
        {
            ratio=Math.round(ratio*100);
        }
        return ratio;
    }
})(window, document);
