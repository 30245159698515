let requestContext = {}
//自动挂在请求方法
let apiAllContext = require.context("/src/api",true,/\.js$/)
apiAllContext.keys().forEach(key =>{
    if(!/http|axios/.test(key)){
        const con = apiAllContext(key).default
        // 不设置name 默认拿文件名
        requestContext[con.name === undefined ? key.split("/")[key.split("/").length-1].split(".")[0] : con.name] = con
    }
})
export default requestContext;