import React, {useState} from 'react'
import "./index.scss"
import {connect} from "react-redux";

const Index = (props) => {
  const { userInfo } = props
  /**state  state部分**/

  /**effect  effect部分**/


  /**methods 方法部分**/

  /**styles 样式部分**/

  /**render**/

  return (
    <div className={"layout"}>


    </div>
  );
};

const mapStateToProps = (state) => {
  return{
    // 是否登录
    userInfo: state.user.userInfo
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
